/* You can add global styles to this file, and also import other style files */
//@import "./lib/framework/dist/scss/variables";
//@import "~@progress/kendo-theme-bootstrap/scss/all";
//@import "~bootstrap/scss/bootstrap";
//@import "./lib/framework/dist/scss/all";

//
//.contextbar-row {
//  margin-right: 10px;
//  margin-left: 10px;
//  padding-bottom: 10px;
//  padding-top: 10px;
//}
//
//.has-error {
//  color: red;
//
//  .invalid-feedback {
//    display: block;
//  }
//}
//
//.card ~ .card {
//  margin-top: 1rem;
//}
//

@import "~leaflet/dist/leaflet.css";
@import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

.resource-timeline-fc {
  --fc-bg-event-opacity: 1;
  .fc-scrollgrid {
    border-left: none !important;
  }

  .fc-timeline-event {
    padding: 0;
    border: none;
  }
}

.resource-timeline-label {
  a {
    text-decoration: none !important;
    color: var(--black);
    width: inherit;
    height: 100%;

    &:hover {
      color: currentColor;
    }
  }

  .date-label {
    font-weight: 400;
  }

  &:not(:only-of-type) .fc-timeline-slot-frame {
    display: flex !important;
    justify-content: unset !important;
    width: 100%;
  }

  &:only-of-type .fc-timeline-slot-frame {
    justify-content: flex-start !important;
  }
}

.striped-background {
  background: repeating-linear-gradient(
      135deg,
      rgba(#606dbc, 0.3),
      rgba(#606dbc, 0.3) 10px,
      rgba(#465298, 0.3) 10px,
      rgba(#465298, 0.3) 20px
  );

  span {
    color: black;
  }
}

.qpoint-event {
  border-left: 4px solid currentColor;
}
